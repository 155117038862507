@import url(https://fonts.googleapis.com/css?family=Open+Sans);

@font-face { font-family: 'zwodrei'; src: url('../res/zwodrei_bold.ttf'); font-weight: bold; }

body { font-family: 'Open Sans', sans-serif; }

header + footer::before {
    content: "This page does not exist.  We recommend you start with our home page by clicking on the logo above.";
    min-height: calc(100vh - 231px);
    padding: 20px;
    color: black;
    background-color: white;
    display: block;
    text-align: center;
    font-weight: bold;
}

h1, h2, h3 { font-family: 'zwodrei'; }

h4 { font-size: 1.2rem; }

label { font-weight: bold; }
div.custom-checkbox label,
div.custom-radio label
{ font-weight: normal; }
div.custom-checkbox.font-weight-bold label,
div.custom-radio.font-weight-bold label
{ font-weight: bold; }

.text-scblue { color: #003f0a; }
.bg-scblue { background-color: #003f0a; }
/* TODO @@@ .text-scblue.faded .border-scblue .border-scblue.faded .bg-scblue .bg-scblue.faded */

.border-primary.faded { border-color: #b8daff !important; }
.bg-primary.faded, .bg-primary.faded:hover { background-color: #cce5ff !important; }
.text-primary.faded { color: #004085 !important; }

.border-secondary.faded { border-color: #d6d8db !important; }
.bg-secondary.faded, .bg-secondary.faded:hover { background-color: #e2e3e5 !important; }
.text-secondary.faded { color: #383d41 !important; }

.border-success.faded { border-color: #c3e6cb !important; }
.bg-success.faded, .bg-success.faded:hover { background-color: #d4edda !important; }
.text-success.faded { color: #155724 !important; }

.border-warning.faded { border-color: #ffeeba !important; }
.bg-warning.faded, .bg-warning.faded:hover { background-color: #fff3cd !important; }
.text-warning.faded { color: #856404 !important; }

.border-danger.faded { border-color: #f5c6cb !important; }
.bg-danger.faded, .bg-danger.faded:hover { background-color: #f8d7da !important; }
.text-danger.faded { color: #721c24 !important; }

.border-info.faded { border-color: #bee5eb !important; }
.bg-info.faded, .bg-info.faded:hover { background-color: #d1ecf1 !important; }
.text-info.faded { color: #0c5460 !important; }

.feedback-success { background-color: #efe !important; }
.feedback-warning { background-color: #ffe !important; }
.feedback-danger { background-color: #fee !important; }

.clickable { cursor: pointer; }

.link-plain { text-decoration: none !important; }
.cursor-default { cursor: default !important; }

.video { background-color: #f9f9f9; }

.margin-centre { margin: 0 auto; }


.dashboard-layout { /*xs*/
    column-count: 2;
}
@media (min-width: 576px) { /*sm*/
    .dashboard-layout {
        column-count: 3;
    }
}
/*@media (min-width: 768px) { md
	.dashboard-layout {
		column-count: 2;
	}
}*/
@media (min-width: 992px) { /*lg*/
    .dashboard-layout {
        column-count: 4;
    }
}
@media (min-width: 1200px) { /*xl*/
    .dashboard-layout {
        column-count: 6;
    }
}


.standard-layout { /*xs*/
    column-count: 1;
}
.standard-child-layout-2 { /*xs*/
    column-count: 1;
}

@media (min-width: 576px) { /*sm*/
    /*.standard-layout {
        column-count: 3;
    }*/
    .standard-child-layout-2 {
        column-count: 2;
    }
}
@media (min-width: 768px) { /*md*/
    .standard-layout {
        column-count: 2;
    }
}
@media (min-width: 992px) { /*lg*/
    /*.standard-layout {
        column-count: 4;
    }*/
}
@media (min-width: 1200px) { /*xl*/
    .standard-layout {
        column-count: 3;
    }
}


/* After this was the standard setup */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
