a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html { font-size: 14px; }

@media (min-width: 768px) {
    html { font-size: 16px; }
}

/*.navbar-dark { box-shadow: 0 .25rem .75rem #000034; }*/

.navbar-brand img { width: 250px; }
.navbar-brand.sitename { color: white; background-color: #e84249; font-weight: bold; padding: 0 7px; }

.navbar-dark .navbar-nav .nav-link { font-weight: 700; color: #9d9d9d; }

.navbar-dark .navbar-nav .nav-link:active,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus
{ color: #003f0a; }

.navbar-dark .navbar-nav .nav-item.dropdown .dropdown-menu .nav-link:active,
.navbar-dark .navbar-nav .nav-item.dropdown .dropdown-menu .nav-link:hover,
.navbar-dark .navbar-nav .nav-item.dropdown .dropdown-menu .nav-link:focus
{ color: #003f0a; }
