footer { color: #9d9d9d; background-color: #003f0a; min-height: 166px; padding-bottom: 20px; }
footer img { margin-top: 39px; margin-right: 40px; float: right; width: 281px; }
footer ul { padding-top: 35px; padding-left: 40px; display: block; }
footer li { padding: 15px; display: inline-block; }
footer li.special { background-color: black; }
footer li a { font-size: 12pt; font-weight: bold; color: #bbbbbb; }
footer li.special a { color: #bbbbbb; }
footer a:hover { color: white; text-decoration: none; }
footer p { font-size: 10pt; margin-top: 17px; margin-left: 55px; color: #bbbbbb; }
footer p a { color: white; }
@media only print {
	footer p { margin: 0; }
	footer img { margin: 0; float: none; }
}
