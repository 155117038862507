.banner {
	position: relative;
	min-height: 400px;
	max-height: 700px;
	width: 100%;
	object-fit: cover;
	background-color: #003f0a;
	overflow: hidden;
	z-index: 1;
}

.bannertext {
	color: white;
	position: absolute;
	top: 250px;
	letter-spacing: 0;
	width: 100%;
	z-index: 2;
}
.bannertext > div {
	max-width: 1024px;
	margin: 0 auto;
	font-family: 'zwodrei', sans-serif;
	font-weight: bold;
	padding-left: 20px;
}
.bannertext > div > div:nth-child(1) { font-size: 26pt; }
.bannertext > div > div:nth-child(2) { font-size: 64pt; margin-top: -22px; }
.bannertext > div > div:nth-child(3) { font-size: 20pt; margin-top: -16px; }
.bannertext > div > div:nth-child(4) { 
	font-family: 'Open Sans', sans-serif;
	font-size: 13pt; 
	margin-top: 40px; 
	margin-bottom: 40px;
}
.bannertext a { text-decoration: none; padding: 14px 35px; background-color: #e84249; color: white; }
@media screen and (max-height: 550px) {
	.banner { height: 500px; }
	.bannertext { top: 80px; }
}

.what { text-align: center; padding: 60px 0; }
.what h1 { font-size: 26pt; font-weight: bold; color: #000034; }
.what .col-sm-4 {padding: 50px 50px 0 50px; }
.what img { max-height: 268px; max-width: 100%; }
.what h2 { font-size: 13pt; font-weight: bold; color: #e84249; margin-top: 20px; }


.mantra { background-color: #f9f9f9; padding: 60px 0; }
.mantra .col-sm-4 { padding: 0 30px; }
.qed { font-family: 'zwodrei', sans-serif; font-size: 40pt; line-height: 1.2em; padding: 0; }
.qed div { color: #003f0a; }
.mantrabutton { margin-top: 40px; }
.mantrabutton a { text-decoration: none; font-weight: bold; padding: 15px; background-color: #003f0a; color: white; }


.news { background-color: #f9f9f9; padding: 20px; }
