@media only screen {
	.page {
		min-height: calc(100vh - 273px);
		margin:	10px auto;
	}

	.page.wide {
		width: 100%;
		max-width: 100%;
		margin: 0;
		padding: 10px 20px;
	}
}

@media only print {
	.page {
		width: 100%;
		max-width: 100%;
		margin: 0;
	}
}
