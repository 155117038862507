@import url(https://fonts.googleapis.com/css?family=Open+Sans);
/* Required because the day-of-week check box controls appear over the calendar popup when generating appointments. */
.react-datepicker-popper {
	z-index: 10;
}

footer { color: #9d9d9d; background-color: #003f0a; min-height: 166px; padding-bottom: 20px; }
footer img { margin-top: 39px; margin-right: 40px; float: right; width: 281px; }
footer ul { padding-top: 35px; padding-left: 40px; display: block; }
footer li { padding: 15px; display: inline-block; }
footer li.special { background-color: black; }
footer li a { font-size: 12pt; font-weight: bold; color: #bbbbbb; }
footer li.special a { color: #bbbbbb; }
footer a:hover { color: white; text-decoration: none; }
footer p { font-size: 10pt; margin-top: 17px; margin-left: 55px; color: #bbbbbb; }
footer p a { color: white; }
@media only print {
	footer p { margin: 0; }
	footer img { margin: 0; float: none; }
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html { font-size: 14px; }

@media (min-width: 768px) {
    html { font-size: 16px; }
}

/*.navbar-dark { box-shadow: 0 .25rem .75rem #000034; }*/

.navbar-brand img { width: 250px; }
.navbar-brand.sitename { color: white; background-color: #e84249; font-weight: bold; padding: 0 7px; }

.navbar-dark .navbar-nav .nav-link { font-weight: 700; color: #9d9d9d; }

.navbar-dark .navbar-nav .nav-link:active,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus
{ color: #003f0a; }

.navbar-dark .navbar-nav .nav-item.dropdown .dropdown-menu .nav-link:active,
.navbar-dark .navbar-nav .nav-item.dropdown .dropdown-menu .nav-link:hover,
.navbar-dark .navbar-nav .nav-item.dropdown .dropdown-menu .nav-link:focus
{ color: #003f0a; }

#toaster {
	position: fixed;
	top: 80px;
	right: 20px;
	z-index: 2000;
	background-color: rgba(0, 0, 0, 0.2);
}
@font-face { font-family: 'zwodrei'; src: url(/static/media/zwodrei_bold.d78ccdf8.ttf); font-weight: bold; }

body { font-family: 'Open Sans', sans-serif; }

header + footer::before {
    content: "This page does not exist.  We recommend you start with our home page by clicking on the logo above.";
    min-height: calc(100vh - 231px);
    padding: 20px;
    color: black;
    background-color: white;
    display: block;
    text-align: center;
    font-weight: bold;
}

h1, h2, h3 { font-family: 'zwodrei'; }

h4 { font-size: 1.2rem; }

label { font-weight: bold; }
div.custom-checkbox label,
div.custom-radio label
{ font-weight: normal; }
div.custom-checkbox.font-weight-bold label,
div.custom-radio.font-weight-bold label
{ font-weight: bold; }

.text-scblue { color: #003f0a; }
.bg-scblue { background-color: #003f0a; }
/* TODO @@@ .text-scblue.faded .border-scblue .border-scblue.faded .bg-scblue .bg-scblue.faded */

.border-primary.faded { border-color: #b8daff !important; }
.bg-primary.faded, .bg-primary.faded:hover { background-color: #cce5ff !important; }
.text-primary.faded { color: #004085 !important; }

.border-secondary.faded { border-color: #d6d8db !important; }
.bg-secondary.faded, .bg-secondary.faded:hover { background-color: #e2e3e5 !important; }
.text-secondary.faded { color: #383d41 !important; }

.border-success.faded { border-color: #c3e6cb !important; }
.bg-success.faded, .bg-success.faded:hover { background-color: #d4edda !important; }
.text-success.faded { color: #155724 !important; }

.border-warning.faded { border-color: #ffeeba !important; }
.bg-warning.faded, .bg-warning.faded:hover { background-color: #fff3cd !important; }
.text-warning.faded { color: #856404 !important; }

.border-danger.faded { border-color: #f5c6cb !important; }
.bg-danger.faded, .bg-danger.faded:hover { background-color: #f8d7da !important; }
.text-danger.faded { color: #721c24 !important; }

.border-info.faded { border-color: #bee5eb !important; }
.bg-info.faded, .bg-info.faded:hover { background-color: #d1ecf1 !important; }
.text-info.faded { color: #0c5460 !important; }

.feedback-success { background-color: #efe !important; }
.feedback-warning { background-color: #ffe !important; }
.feedback-danger { background-color: #fee !important; }

.clickable { cursor: pointer; }

.link-plain { text-decoration: none !important; }
.cursor-default { cursor: default !important; }

.video { background-color: #f9f9f9; }

.margin-centre { margin: 0 auto; }


.dashboard-layout { /*xs*/
    -webkit-column-count: 2;
            column-count: 2;
}
@media (min-width: 576px) { /*sm*/
    .dashboard-layout {
        -webkit-column-count: 3;
                column-count: 3;
    }
}
/*@media (min-width: 768px) { md
	.dashboard-layout {
		column-count: 2;
	}
}*/
@media (min-width: 992px) { /*lg*/
    .dashboard-layout {
        -webkit-column-count: 4;
                column-count: 4;
    }
}
@media (min-width: 1200px) { /*xl*/
    .dashboard-layout {
        -webkit-column-count: 6;
                column-count: 6;
    }
}


.standard-layout { /*xs*/
    -webkit-column-count: 1;
            column-count: 1;
}
.standard-child-layout-2 { /*xs*/
    -webkit-column-count: 1;
            column-count: 1;
}

@media (min-width: 576px) { /*sm*/
    /*.standard-layout {
        column-count: 3;
    }*/
    .standard-child-layout-2 {
        -webkit-column-count: 2;
                column-count: 2;
    }
}
@media (min-width: 768px) { /*md*/
    .standard-layout {
        -webkit-column-count: 2;
                column-count: 2;
    }
}
@media (min-width: 992px) { /*lg*/
    /*.standard-layout {
        column-count: 4;
    }*/
}
@media (min-width: 1200px) { /*xl*/
    .standard-layout {
        -webkit-column-count: 3;
                column-count: 3;
    }
}


/* After this was the standard setup */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.banner {
	position: relative;
	min-height: 400px;
	max-height: 700px;
	width: 100%;
	object-fit: cover;
	background-color: #003f0a;
	overflow: hidden;
	z-index: 1;
}

.bannertext {
	color: white;
	position: absolute;
	top: 250px;
	letter-spacing: 0;
	width: 100%;
	z-index: 2;
}
.bannertext > div {
	max-width: 1024px;
	margin: 0 auto;
	font-family: 'zwodrei', sans-serif;
	font-weight: bold;
	padding-left: 20px;
}
.bannertext > div > div:nth-child(1) { font-size: 26pt; }
.bannertext > div > div:nth-child(2) { font-size: 64pt; margin-top: -22px; }
.bannertext > div > div:nth-child(3) { font-size: 20pt; margin-top: -16px; }
.bannertext > div > div:nth-child(4) { 
	font-family: 'Open Sans', sans-serif;
	font-size: 13pt; 
	margin-top: 40px; 
	margin-bottom: 40px;
}
.bannertext a { text-decoration: none; padding: 14px 35px; background-color: #e84249; color: white; }
@media screen and (max-height: 550px) {
	.banner { height: 500px; }
	.bannertext { top: 80px; }
}

.what { text-align: center; padding: 60px 0; }
.what h1 { font-size: 26pt; font-weight: bold; color: #000034; }
.what .col-sm-4 {padding: 50px 50px 0 50px; }
.what img { max-height: 268px; max-width: 100%; }
.what h2 { font-size: 13pt; font-weight: bold; color: #e84249; margin-top: 20px; }


.mantra { background-color: #f9f9f9; padding: 60px 0; }
.mantra .col-sm-4 { padding: 0 30px; }
.qed { font-family: 'zwodrei', sans-serif; font-size: 40pt; line-height: 1.2em; padding: 0; }
.qed div { color: #003f0a; }
.mantrabutton { margin-top: 40px; }
.mantrabutton a { text-decoration: none; font-weight: bold; padding: 15px; background-color: #003f0a; color: white; }


.news { background-color: #f9f9f9; padding: 20px; }

@media only screen {
	.page {
		min-height: calc(100vh - 273px);
		margin:	10px auto;
	}

	.page.wide {
		width: 100%;
		max-width: 100%;
		margin: 0;
		padding: 10px 20px;
	}
}

@media only print {
	.page {
		width: 100%;
		max-width: 100%;
		margin: 0;
	}
}

